:root {
  --primary-btn: #0a58ce; /* Base color for buttons */
  --primary-btn-hover: #084298; /* Darker shade for hover effect */
  --btn-danger: #d62020; /* Base color for cancel buttons */
  --btn-hover-danger: #B71C1C; /* Base color for cancel buttons */
  --text-color: #ffffff; /* White text for contrast */
}

ol,
ul {
  padding-left: 0 !important;
}

a {
  text-decoration: none !important;
}

input,
select {
  width: 100% !important;
}

.p-icon-field {
  width: 100% !important;
}

.p-inputwrapper {
  width: 100% !important;
}

.ps-toolbar {
  /* color: aliceblue; */
  color: #1b2833;

}

.p-avatar {
  background-color: #b0bacd;
}

.error {
  font-size: 14px;
  color: rgb(231, 12, 12);
  /* min-height: 20px; */
}

.p-inputtext,
.btn {
  border-radius: 14px !important;
}

.p-inputtext {
  border: 1px solid #aeb3c9;
}

.card {
  /* border: 1px solid #5382c7;
  border-radius: 0 !important; */

  /* border: 1px solid #5382c7; */
  border-radius: 0px;

}

.card {
  background-color: #ffffff8a !important;
}


.card-footer {
  border-top: none;


}

.box-footer {
  border-top: none !important;
  margin-top: 30px;
  background-color: #e9e9e9 !important;
}

.p-listbox-list-wrapper {
  max-height: 460px;
}

.basic-btn {
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  border: 1px solid gray;
}

.checkedin {
  background-color: #50c550;
  color: white;
  border: none;
  padding-top: 20px;
  padding-bottom: 20px;

}

.checkedout {
  background-color: rgb(182, 53, 63);
  color: white;
  border: none;
  padding-top: 20px;
  padding-bottom: 20px;
}

.payment-icons img {
  width: 100%;

}

.payment-icons img:hover {
  opacity: 0.8;
}

.cursor-pointer {
  cursor: pointer;
}


/* ----------------------------- */
.toggle-btn * {
  width: 20px;
  height: 20px;
  color: rgb(82, 80, 80)
}

.app-border {
  border: 1px solid #cfcfcf;
}

.app-title {
  font-size: 24px;
  color: #0055a2;
  font-weight: bold;
}

.app-title2 {
  font-size: 16px;
  color: #0055a2;
  font-weight: bold;
}

.app-btn-primary {
  background-color: #0055a2 !important;
  color: #dbedff !important;
  border: none !important;
  padding: 0.60rem 0.75rem !important;
  width: 100%;
}

.app-btn-danger {
  background-color: #d20303 !important;
  color: #dbedff !important;
  border: none !important;
  padding: 0.60rem 0.75rem !important;
  width: 100%;
}

.heading {
  font-size: 16px;
}

.paragraph {
  font-size: 13px;
}

.notes {
  font-size: 12px;
}
.notes1 {
  font-size: 11px;
}

.notes2 {
  font-size: 10px;
}

.p-inputtext {
  font-size: 1rem;
  color: #4b5563;
  background: #ffffff;
  padding: 0.60rem 0.75rem !important;
}

.loan-box {
  border: 1px solid #0055a2;
  color: #0055a2;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  background-color: #ffffff;
  cursor: pointer;
}


.loan-box:hover {
  transform: scale(1.05);
  background-color: #a1a1a4;
}

.loan-box-active {
  background-color: #e0e0e3ed!important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  border: 0px #e5e7eb !important;
  color: #374151;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ps-menu-icon {
    font-size: 1.5em;
    font-weight: 100 !important;
    color: #6c757d !important;
}

.ps-menu-icon-active {
  color: rgb(255, 255, 255) !important;
}

.css-18unl23 {
  background: none !important;
}

.css-dip3t8 {
  z-index: 3;
  background-color: rgb(255, 255, 255) !important;
  padding-left: 15px;
  padding-right: 15px;
}
.table-history td{
  border-top: 1px solid rgb(216, 215, 218);
  
}
.SvgContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 2px solid #000; /* Optional border for visualization */
  border-radius: 8px; /* Optional rounded corners */
  background-color: #f0f0f0; /* Optional background color */
}
/*add space between words*/
.spaced-text {
  margin: 0 2px; /* Adjust spacing as needed */
}
