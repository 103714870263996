.credit-card {
  width: 300px;
  height: 180px;
  perspective: 1000px;
  cursor: pointer;
}

.credit-card .card-front,
.credit-card .card-back {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;

}

.credit-card .card-front {
  background: linear-gradient(135deg, #f8f9fa, #eaedef);
  color: rgb(52, 52, 54);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}

.card-back {
  background: #f8f9fa;
  color: #333;
  transform: rotateY(180deg);
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: start;
  
}

.credit-card.flipped .card-front {
  transform: rotateY(180deg);
}

.credit-card.flipped .card-back {
  transform: rotateY(360deg);
}

.card-brand {
  font-size: 14px;
  font-weight: bold;
}
.card-title {
  font-size: 12px;
  font-weight: bold;
  
}
.card-back-title{
  font-size: 12px;
  font-weight: bold;
}

.card-number {
  font-size: 16px;
  letter-spacing: 2px;
}
.card-back-accno{
  font-size: 12px;
  padding-top: 1px;
  letter-spacing: 2px;
  font-weight: bold;
}

.card-data,
.card-date,
.card-back-txt {
  font-size: 10px;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  
}

.card-strip {
  width: 100%;
  height: 2.0px;
  background: linear-gradient(135deg, #dbd9d9, #e8eaea);
  
  margin-bottom: 5px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}
.card-meta {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 5px;
  }
  .card-text{
    font-size: 12px;
    font: normal;
  }
  .card-text-bold{
    font-size: 12px;
    font-weight: 500;
    font: normal;
  }